//import logo from './logo.svg';

import React, { useEffect, useState } from 'react';
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import Papa from 'papaparse';
import { jsPDF } from "jspdf";

import logo from './jr-logo.png';
import 'bulma/css/bulma.min.css';
import './App.scss';


/*

IN PROGRESS
- Style form

*/


function App() {

  // Get current date
  var currentDate = new Date();
  var date = currentDate.getDate()+'.'+(currentDate.getMonth()+1)+'. '+currentDate.getFullYear();

  // Csv to json with papaparse
  const [parsedCsvData, setParsedCsvData] = useState([]);

  useEffect(() => {
    Papa.parse('/jr/codes.csv', {
      header: true,
      download: true,
      dynamicTyping: true,
      complete: function(results) {
        //console.log('Results:', results);
        setParsedCsvData(results.data);
      }
    });

  }, []);
  //console.log('Parsed csvFile:', parsedCsvData);


  // Manage form with react hook form
  const {
    register,
    watch,
    formState,
    formState: { errors },
    handleSubmit
  } = useForm({
    criteriaMode: "all"
  });

  // Form submitted?
  const [formSubmitted, setFormSubmitted] = useState(false);


  // Names with useState
  const [inputName, setInputName] = useState();
  const [inputSurname, setInputSurname] = useState();
  const fullName =  inputName + ' ' + inputSurname;


  // Find nested Object that contains a value
  var n;
  let findByValue = (o, val) => {
    if (o === val) return o;
    if (o === NaN || o === Infinity || !o || typeof o !== 'object') return;
    if (Object.values(o).includes(val)) return o;
    for (n of Object.values(o)) {
      const found = findByValue(n, val)
      if (found) return n
    }
  }

  // watch input value by passing the name of it
  const currentCode = watch("current_code", false);
  //console.log("Current Code (watch) after submit is:", currentCode);

  // if code matches, get associated image name
  let codeResult = findByValue(parsedCsvData, currentCode);

  let matchingImageName = '';

  if (typeof codeResult !== 'undefined') {
    matchingImageName = codeResult.urkunde;
  }

  let currentImageName = 'ruegen';

  let currentImagePath = `/jr/images/${currentImageName}.jpg`;

  var currentImage = new Image();
  currentImage.src = currentImagePath;

  if ( matchingImageName ) {
    currentImageName = matchingImageName;
    currentImagePath = `/jr/images/${currentImageName}.jpg`;
    currentImage.src = currentImagePath;
  }

  // Pdf with jspdf: Image, text style and positioning
  const generatePdf = () => {
    const doc = new jsPDF('1', 'pt', 'a4');
    doc.addImage(currentImage, "JPEG", 0, 0, 595, 842);
    doc.setFontSize(18);
    //doc.text(fullName, 180, 469);
    doc.text(fullName, 180, 456);
    doc.setFontSize(14);
    //doc.text(date, 180, 703);
    doc.text(date, 258, 688);
    doc.save("jr-urkunde.pdf");
  };
  // console.log('pdf image:', currentImage);

  const submitForm = (data) => {
    //console.log(data);
    setFormSubmitted(true);
  };

  const bothFieldsAreFilled = inputName && inputSurname;

  // Reset form
  function refreshPage() {
    window.location.reload();
  }


  return (
    <div className="App">
      <main>
        <header>
         <a href="/"><img src={logo} className="app-logo" alt="Junior Ranger Entdeckercode" /></a>
        </header>
        <div className="form text-left">

        <div className="call-to-action">
          <h1 className="title">Entdeckercode</h1>


          <form onSubmit={handleSubmit(submitForm)}>

          <div className="field has-addons inputbox">
            <div className="control input-wrapper">
            <input
            className={matchingImageName ? ("input is-success") : ("input") }
            type="text"
            placeholder="Dein Entdeckercode..."
            {...register("current_code", {
              required: "Pflichfeld, bitte ausfüllen!",
              pattern: {
                value: /[A-Za-z]/,
                message: "Bitte nur Text eintragen."
              },
              maxLength: {
                value: 80,
                message: "Bitte nicht mehr als 80 Zeichen eintragen."
              }
            })}
            disabled={formState.isSubmitting || matchingImageName}
            />
            <ErrorMessage
              errors={errors}
              name="current_code"
              render={({ messages }) => {
                console.log("messages", messages);
                return messages
                  ? Object.entries(messages).map(([type, message]) => (
                      <p className="help is-danger" key={type}>{message}</p>
                    ))
                  : null;
              }}
            />

            </div>

            <div className="control">
              <input
                className="button is-dark"
                type="submit"
                value="Los!"
                disabled={formState.isSubmitting || matchingImageName}
              />
            </div>
          </div>
          { formSubmitted ?
            ( matchingImageName ?
             (
              <span className="help is-success">Code passt.</span>
             ) : (
              <span className="help is-danger">Leider falsch, versuch es noch einmal!</span>
            )
            ) : null
          }

          </form>
        </div>

        {matchingImageName ?

                   (
                    <div className="call-to-action">
                        <div className="field is-size-6">
                            <p className="congrats">Glückwunsch zum bestandenen Entdeckerheft!</p>
                            <p> Nun gib deinen Namen ein, um deine Urkunde zu bekommen.</p>
                        </div>
                       <div className="field">
                          <label className="label">Vorname</label>
                          <div className="control">
                            <input
                              className="input"
                              type="text"
                              placeholder="Dein Vorname"
                              onChange={(event) => {
                                 setInputName(event.target.value);
                                 }
                              }
                              required
                            />
                          </div>
                        </div>

                        <div className="field">
                           <label className="label">Nachname</label>
                           <div className="control">
                             <input
                              className="input"
                              type="text"
                              placeholder="Dein Nachname"
                              onChange={(event) => {
                                setInputSurname(event.target.value);
                                }
                              }
                              required
                              />
                           </div>
                         </div>

                         <div className="field is-grouped">

                         <div className="control">

                         <button
                           className="button"
                           type="reset"
                           onClick={refreshPage}
                          >
                          Zurücksetzen
                          </button>
                          </div>

                         <div className="control">
                         { bothFieldsAreFilled
                           ? <button className="button is-dark" onClick={() => { generatePdf(); }}>Download PDF</button>
                           : null
                         }
                         </div>

                          </div>

                       </div>
                   ) :
                   (
                       <div className="default-field is-size-6">
                           <p>
                             Gib deinen Entdeckercode ein, um zur Urkunde zu gelangen!
                           </p>
                       </div>
                   )

           }


        </div>

        <div className="preview">
          <div className="box certificate-box">
          { matchingImageName
            ? <img src={currentImagePath} className="certificate-bg" alt="Junior Ranger Urkunde" />
            : <img src={'/jr/images/lars-lotta.png'} className="certificate-bg" alt="Lars und Lotta - Hol dir deine Urkunde" />
          }

           <p className="certificate-fullname">{inputName} {inputSurname}</p>
           </div>

           <div className="controlbox help notification is-info is-light">
             <p>
               Code input:  { /* currentCode */ }<br />
             </p>
             <p>Parsed Data:</p>
             <div className="align-center">
               <table className="table">
                 <thead>
                   <tr>
                     <th>Code</th>
                     <th>Urkunde</th>
                   </tr>
                 </thead>
                 <tbody>
                   {
                   /*
                   parsedCsvData &&
                     parsedCsvData.map((parsedData, index) => (
                       <tr key={index}>
                         <td>{parsedData.code}</td>
                         <td>{parsedData.urkunde}</td>
                       </tr>
                     ))
                     */
                     }
                 </tbody>
               </table>
               <p>
                 Matching image: { /* matchingImageName */ } <br />
                 Current image path: { /* currentImagePath */ }
               </p>
               </div>
           </div>

        </div>

      </main>
      <footer className="section">
        <p>
          Das bundesweite <a href="https://junior-ranger.de/">Junior-Ranger-Programm</a> ist ein gemeinsames Programm der deutschen Nationalparke, Naturparke, Biosphärenreservate und Wildnisgebiete in Zusammenarbeit mit ihren Dachverbänden <a href="https://nationale-naturlandschaften.de/impressum">Nationale Naturlandschaften e.V.</a> und <a href="https://www.naturparke.de/impressum.html">Verband Deutscher Naturparke e. V.</a>
        </p>
      </footer>
    </div>
  );
}

export default App;
